<template>
  <div class="wrapper">
    <div class="withdrawal inner-wrapper">
      <div class="accounts-form" v-show="!displayed.loading">
        <div class="d-flex df-row">
          <h1
            class="accounts-form__small-bi-header cursor"
            @click="$router.push('/introduction/main')"
          >
            Qpick
          </h1>
          <div class="spacer"></div>
          <button class="close-btn" @click="closeWindow"></button>
        </div>
        <h1 class="withdrawal accounts-form__header">회원탈퇴</h1>
        <div class="accounts-form__description">
          탈퇴 시 계정에 등록된 모든 데이터는 삭제되며<br />
          복구할 수 없습니다.
        </div>
        <outlinedTextField
          placeholder="비밀번호"
          @value="setPassword"
          class="accounts-form__outlined-text"
          type="password"
          ref="password"
        ></outlinedTextField>
        <p class="accounts-form__form-error" v-if="error.password">
          {{ error.password }}
        </p>
        <button class="accounts-form__submit" @click="passwordCheck">
          탈퇴하기
        </button>
      </div>
    </div>
    <toastMsg ref="toast"></toastMsg>
  </div>
</template>

<script>
import Vue from "vue";
import {createNamespacedHelpers} from "vuex";
import VueCompositionAPI from "@vue/composition-api";
import outlinedTextField from "@/components/accounts/outlined-text-field.vue";
import toastMsg from "@/components/accounts/toast-message.vue";
import authAPI from "@/api/accounts/auth.js";
import userAPI from "@/api/accounts/user.js";
import "@/assets/scss/accounts/basis.scss";
import "@vueform/toggle/themes/default.css";
import {removeAuthToken} from "@/utils/auth.js";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");
const ModuleUserSite = createNamespacedHelpers("ModuleUserSite");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");

Vue.use(VueCompositionAPI);

export default {
  data() {
    return {
      user_id: null,
      password: "",
      error: {password: ""},
      displayed: {loading: true},
      callback: ""
    };
  },
  methods: {
    setPassword(v) {
      this.password = v;
    },
    closeWindow() {
      window.close();
    },
    //비밀번호 검증
    passwordCheck() {
      userAPI.checkPassword(this.password).then(resPassword => {
        if (resPassword.status === 204) {
          this.setConfirm({
            isVisible: true,
            message: `탈퇴하고 모든 데이터를 삭제하시겠습니까?`,
            messageBtn: `탈퇴하기`,
            event: () => this.accountDelete()
          });
        } else {
          this.setAlert({
            isVisible: true,
            message: "비밀번호가 맞지 않습니다."
          });
          // this.error.password = resPassword.data.errors[0].userMsg;
        }
      });
    },
    //회원탈퇴
    accountDelete() {
      this.$store.dispatch("ModuleUser/actUserLog", {
        eventName: "회원탈퇴",
        requestLocation: "큐픽 > 계정정보"
      });
      userAPI.withdrawal(this.user_id).then(res => {
        if (res.status === 204) {
          this.setAlert({
            isVisible: true,
            message: "탈퇴 처리가 완료되었습니다.",
            event: this.callback ? "callback" : "withdrawal"
          });
        } else {
          this.setAlert({
            isVisible: true,
            message: res.data.errors[0].userMsg
          });
          // this.$refs.toast.show(res.data.errors[0].userMsg);
        }
      });
    },
    //솔루션 연동정보 목록
    userSolutionList() {
      let payload = {};
      payload.userId = this.user_id;
      payload.sortBy = "-createdAt";

      this.actUserSolutionList(payload).then(response => {
        switch (response.status) {
          case 200:
            if (response.data.list.length > 0) {
              this.setAlert({
                isVisible: true,
                message:
                  "현재 사용중인 솔루션이 있습니다.<br>솔루션 연동을 모두 해제하신 후 탈퇴 가능합니다.",
                event: "not_allowed"
              });
            } else {
              this.displayed.loading = false;
            }
            break;

          default:
            this.setAlert({
              isVisible: true,
              message: response.data.errors[0].userMsg,
              event: "back"
            });
            break;
        }
      });
    },
    ...ModuleAlert.mapMutations([
      "setAlertVisible",
      "setAlertMessage",
      "setAlert"
    ]),
    ...ModuleConfirm.mapMutations([
      "setConfirmVisible",
      "setConfirmMessage",
      "setConfirmBtnMessage",
      "setConfirm"
    ]),
    ...ModuleUserSite.mapActions(["actUserSolutionList"]),
    ...ModuleAuth.mapMutations(["setLoginStatus"])
  },
  components: {
    outlinedTextField,
    toastMsg
  },
  mounted() {
    authAPI.logged().then(res => {
      if (res.status == 200) {
        //준회원 접근제한 처리
        if (res.data.grade < 1) {
          this.setAlert({
            isVisible: true,
            message: "접근 권한이 없습니다.",
            event: "not_allowed"
          });
        } else {
          this.user_id = res.data.id;
          this.userSolutionList();
        }
      } else {
        this.setAlert({
          isVisible: true,
          message: "로그인된 회원만 사용할 수 있습니다.",
          event: "login"
        });
      }
    });
  },
  created() {
    //콜백 파라미터 여부
    if (this.$route.query.callback) {
      this.callback = this.$route.query.callback;
    }

    this.$EventBus.$on("alertCancel", event => {
      switch (event) {
        case "withdrawal":
          this.setLoginStatus(false);
          removeAuthToken();
          this.$router.push("/introduction/main");
          break;

        case "back":
          this.$router.go(-1);
          break;

        case "not_allowed":
          this.$router.push("/admin/dashboard");
          break;

        case "login":
          this.$router.push("/accounts/login");
          break;

        case "callback":
          this.callback.match(/^http(s*):\/\//)
            ? (location.href = this.callback)
            : this.$router.push(this.callback);
          break;
      }
    });
  }
};
</script>

<style>
@media screen and (max-width: 767px) {
  .close-btn {
    width: fit-content;
    height: fit-content;
    padding-top: 27px;
    font-size: 0;
  }
  .close-btn::after {
    content: "×";
    font-size: 24px;
  }
}
</style>
